<template>
  <div style="margin-right:24px">
    <div class="topDetail">
      <div class="left">
        <img class="img" :src="require('@/assets/main/2.png')">
        <div class="text">{{ info.pbName }}</div>
        <div v-if="info.isFindPb==true" class="isGood center">{{ info.pbHonorName }}</div>
      </div>
      <div class="right center click" @click="$router.push({ path: '/GetPbDemDetail', query: { id:info.id }})">查看党组织详情></div>
    </div>
    <div class="address">{{ info.address }}</div>
    <div class="fourItem">
      <div class="item">
        <div class="title">党员数量</div>
        <div class="count">{{ info.pmCount }}</div>
      </div>
      <div class="item">
        <div class="title">下级党组织</div>
        <div class="count">{{ info.childCount }}</div>
      </div>
      <div class="item">
        <div class="title">专题数</div>
        <div class="count">{{ info.newsCount }}</div>
      </div>
      <div class="item">
        <div class="title">活动会议</div>
        <div class="count">{{ info.meetingCount }}</div>
      </div>
    </div>
    <div class="summary">
      <div class="title">组织概述：</div>
      <div class="content">{{ info.summary }}</div>
    </div>
    <div class="line" />
    <div v-if="info.childData && info.childData.length" class="bottomOrganization">
      <div class="title">下级党组织</div>
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item v-for="(item,index) in info.childData" :key="index" :name="index+''">
          <template slot="title">
            <div class="customTitle">
              <div class="titles" @click="goDetail(item.id)">{{ item.pbName }}</div>
              <div v-if="item.isFindPb" class="isGood center">{{ item.pbHonorName }}</div>
            </div>
          </template>
          <div class="item">
            <div style="margin-top:20px">支部地址：{{ item.address }}</div>
            <div>组织概述：{{ item.summary }}</div>
            <div style="margin-bottom:39px">
              <div>下级支部：{{ item.childCount }}个</div>
              <!-- <div v-if="item.childCount" class="center click" @click="goDetail(item.id)">查看下级党组织></div> -->
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
      },
      activeName: ''
    }
  },
  watch: {
    $route() {
      this.getData()
      this.$nextTick(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      })
    }
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      this.$parent.loading = true
      const id = this.$getParams().id
      const param = {}
      if (id) param.pbId = id
      this.$store.dispatch('main/PbGetPbDem', { ...param }).then(res => {
        if (res.code === 0) {
          this.info = res.data
          this.$parent.loading = false
        }
      })
    },
    goDetail(id) {
      if (id == this.$getParams().id) return
      this.$router.push({ path: '/GetPbDem', query: { id }})
    }
  }
}
</script>

<style lang="scss" scoped>
      .isGood{
        margin-left: 9px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--theme);
        line-height: 32px;
        width: 90px;
        height: 24px;
        border: 1px solid var(--theme);
        border-radius: 4px;
        position: relative;
        &::after{
          content: '';
          background: var(--theme);
          opacity: .1;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
        }
      }
  .topDetail{
    display: flex;
    justify-content: space-between;
    .left{
      display: flex;
      .img{
        width: 30px;
        height: 30px;
        margin-right: 6px;
      }
      .text{
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }

    }
    .right{
      margin-right: 48px;
      width: 150px;
      height: 36px;
      background: #FFFFFF;
      border: 1px solid var(--theme);
      border-radius: 4px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: var(--theme);
      margin-top: 5px;
    }

  }
  .address{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999;
  }
  .fourItem{
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    .item{
      padding-top: 38px;
      padding-bottom:38px;
      width: 224px;
      height: 148px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      &::after{
        content: '';
        background: var(--theme);
        opacity: 0.1;
        position: absolute;
        left: 0;
        top: 0;
        right:0;
        bottom:0;
        z-index: -1;
      }
      .title{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--theme);
      }
      .count{
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--theme);
      }
    }
  }
  .summary{

    .title{
      margin-bottom: 19px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: 32px;
    }
    .content{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
    }

  }
  .line{
    height: 1px;
    background: #EEEEEE;
    margin-top: 40px;
    margin-bottom: 32px;
  }
  .bottomOrganization{
    .title{
      display: flex;
      align-items: center;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 20px;
      margin-bottom: 21px;
      &::before{
        width: 4px;
        height: 22px;
        background: var(--theme);
        margin-right: 6px;
        content: "";
        display: inline-block;
      }
    }
    .item{
      &>div:nth-child(1){
        margin-bottom: 15px;
      }
      &>div:nth-child(2){
        margin-bottom: 20px;
      }
      &>div:nth-child(3){
        display: flex;
        justify-content: space-between;
        align-items: center;
        &>div:nth-child(1){
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
        &>div:nth-child(2){
          width: 140px;
          height: 28px;
          border: 1px solid var(--theme);
          border-radius: 4px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: var(--theme);
        }
      }
      &>div{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .customTitle{
    display: flex;
    align-items: center;
    .titles{
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-bottom: unset;
      text-decoration: underline;
    }
  }
  ::v-deep{
    .el-collapse-item__header{
      border: 1px solid #EBEEF5!important;
      background: #f6f8fa!important;
      padding:20px;
    }
    .el-collapse-item__wrap{
      padding-left: 20px;
      padding-right: 20px;
      background: #f6f8fa!important;
      border-bottom: unset;
    }
    .el-collapse-item{
      margin-bottom: 20px;
    }
    .el-collapse-item__content{
      padding-bottom: unset!important;;
    }
  }
</style>
